import { defineStore } from "pinia";

export const useAyedoStore = defineStore("ayedo", {
	state: () => ({
		auth: '',
		id: '',
		persona: '',
		// UI Params
		organization: null,
	}),
	actions: {
		setAuth(auth) {
			this.auth = auth;
		},
		setId(id) {
			this.id = id;
		},
		setPersona(persona) {
			this.persona = persona;
		},
		logOut() {
			this.auth = 'false';
			this.id = '';
			this.persona = '';
		}
	}
});