import {useAyedoStore} from "@/state/pinia";
import {apiGet} from "@/methods";



export default [
  {
    path: "/",
    name: "default",
    meta: { title: "Select Organization", authRequired: true },
    component: () => import("../views/_app/org-select")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/_app/_auth/login"),
    meta: {
      title: "Sign In",
      beforeResolve(routeTo, routeFrom, next) {
        const ayedo = useAyedoStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if(ayedo.auth === 'true' && ayedo.id && ayedo.persona) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/_app/error-pages/500.vue"),
    meta: {
      title: "Site Currently Unavailable",
      beforeResolve(routeTo, routeFrom, next) {
        next();
      }
    }
  },
  {
    path: "/authenticate",
    name: "authenticate",
    component: () => import("@/views/_app/_auth/login"),
    meta: {
      title: "Authenticate",
      beforeResolve(routeTo, routeFrom, next) {
        next();
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/_app/_auth/logout"),
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
         next();
      }
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        next();
      }
    },
    component: () => import("../views/_dashboard/view")
  },
  {
    path: "/organization/:organizationId",
    name: "organization",
    meta: {
      title: "Overview",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const ayedo = useAyedoStore();
        if(!ayedo.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            ayedo.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/view")
  },
  {
    path: "/organization/:organizationId/invoices",
    name: "organization_invoices",
    meta: {
      title: "Invoices",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const ayedo = useAyedoStore();
        if(!ayedo.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            ayedo.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/invoices")
  },

    /*
  // GENERIC OPERATIONS
  {
    path: "/organizations/new",
    name: "organization_create",
    meta: {
      title: "Create Organization",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        next();
      }
    },
    component: () => import("../views/organizations/create")
  },

  // VIEW TYPE: ORGANIZATION

  {
    path: "/organization/:organizationId/projects",
    name: "organization_projects",
    meta: {
      title: "Projects",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useAyedoStore();
        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_projects/list")
  },
  {
    path: "/organization/:organizationId/projects/new",
    name: "organization_project_create",
    meta: {
      title: "Create Project",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useAyedoStore();
        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_projects/create")
  },
  {
    path: "/organization/:organizationId/project/:projectId",
    name: "organization_project",
    meta: {
      title: "Project",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useAyedoStore();
        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        if(!loopback.project) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}/project/${routeTo.params.projectId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.project = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_projects/view")
  },
  {

    path: "/organization/:organizationId/project/:projectId/workspaces/new",
    name: "organization_project_workspace_create",
    meta: {
      title: "Create Workspace",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useAyedoStore();
        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        if(!loopback.project) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}/project/${routeTo.params.projectId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.project = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_workspaces/create")
  },
  {
    path: "/organization/:organizationId/project/:projectId/workspace/:workspaceId",
    name: "organization_project_workspace",
    meta: {
      title: "Workspace",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useAyedoStore();
        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        if(!loopback.project) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}/project/${routeTo.params.projectId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.project = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        if(!loopback.workspace) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}/workspace/${routeTo.params.workspaceId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.workspace = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_workspaces/view")
  },
  {
    path: "/organization/:organizationId/hosts",
    name: "organization_hosts",
    meta: {
      title: "Hosts",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useAyedoStore();
        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/organizations/hosts")
  },
  {
    path: "/organization/:organizationId/host/:hostId",
    name: "organization_host",
    meta: {
      title: "Host",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useAyedoStore();
        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/organizations/host")
  },
  {
    path: "/organization/:organizationId/host/:hostId/shell",
    name: "organization_host_shell",
    meta: {
      title: "Host Shell",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useAyedoStore();
        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_host/shell.vue")
  },
  {
    path: "/system/engine-templates",
    name: "engine_templates",
    meta: { title: "Engine Templates", authRequired: true },
    component: () => import('../views/_system/engine-templates/list')
  },
  {
    path: "/system/engine-template/:engineTemplateId",
    name: "engine_template_editor",
    meta: { title: "Engine Template Editor", authRequired: true },
    component: () => import('../views/_system/engine-templates/editor.vue')
  },
  {
    path: "/engine-template/:engineTemplateId",
    name: "engine_template_editor_old",
    meta: { title: "Engine Template Editor", authRequired: true },
    component: () => import('../views/_system/engine-templates/editor_old.vue')
  },
  */


  {
    path: "/404",
    name: "404",
    meta: { title: "404", authRequired: true },
    component: () => import('../views/_app/error-pages/404.vue')
  },
  {
     path: "/:pathMatch(.*)*",
     redirect: "404",
  },
];
