let _timestampInit = (new Date).getTime();



import Vue, {createApp, nextTick} from 'vue'

import App from './App.vue'
import router from "./router";
import i18n from "./i18n";

import { vMaska } from "maska"
import pinia from "@/state/pinia";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';
import BootstrapVueNext from 'bootstrap-vue-next';
import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2';
import mitt from 'mitt';

// Stores
import { useAyedoStore } from "./state/pinia/ayedo";
import { connectWebSocket } from "./ws";

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import {apiDelete, apiFile, apiGet, apiPatch, apiPost, apiPut, formatBytes, parseISO8601String} from '@/methods';

let VueApp;
(
    async () => {
		const wsBus = mitt();
		wsBus.on('init', () => {
			console.info('[WS] Wroom wroom');
		});

		let apiUnavailable = false;

        VueApp = createApp(App);
		VueApp.provide('wsBus', wsBus);


		VueApp.use(pinia);
		/*
		 * Seed Store
		 */
		let bootstrapRequest = await apiGet('v1/@me', {autoErrorHandler: false});
		if(!bootstrapRequest.result) {
			if([418, 500, 501, 502, 503].includes(bootstrapRequest.status) || bootstrapRequest.status === null || bootstrapRequest.status === undefined) {
				apiUnavailable = true;
			}
		} else {
			const ayedo = useAyedoStore();
			ayedo.setAuth('true');
			ayedo.setId(bootstrapRequest.response.id);
			ayedo.setPersona(bootstrapRequest.response.persona);
			//wsBus.emit('wsk', bootstrapRequest.response.wsk);
		}

		// Core plugins
		VueApp.use(router);
		VueApp.use(i18n);

		// Third party
		VueApp.use(BootstrapVueNext);
		VueApp.use(VueApexCharts);
		VueApp.use(vClickOutside);

		VueApp.use(registerScrollSpy);
		VueApp.use(VueSweetalert2);


		// Load custom methods
        VueApp.mixin({
            apiGet: apiGet,
            apiFile: apiFile,
            apiPost: apiPost,
            apiPut: apiPut,
            apiPatch: apiPatch,
            apiDelete: apiDelete,
            parseDate: parseISO8601String,
			formatBytes: formatBytes
        })

		// Delay app render artificially to ensure store is populated and seeded
        setTimeout(() => {
			VueApp.mount('#app');
			if(apiUnavailable) {
				router.push({name: '500'});
			}
		}, 50);
    }
)();

export default {VueApp: VueApp, i18n: i18n};